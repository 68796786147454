import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons
import Language from "@material-ui/icons/Language"
import LocalLibrary from "@material-ui/icons/LocalLibrary"
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"

import aboutStyle from "assets/jss/material-kit-react/views/landingPageSections/aboutStyle.jsx"

class AboutSection extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.section} id="about">
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>About Us</h2>
            <h5 className={classes.description}>
              The mission of UPP is to help Polynesians attain more fulfilling
              and rewarding work. Coming from a Polynesian background, many of
              us were raised with the saying, "it takes a village to raise a
              child." UPP strives to help Polynesians enhance their careers
              through creating a professional village, where community members
              can connect, learn from each other, and help one another grow in
              our respective careers.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Networking"
                description="We hold meetups to bring the `village` together to allow leaders to share their experiences in the workspace. These events are held at local businesses allowing us to highlight our participation in building the success of Utah businesses."
                icon={Language}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Mentoring"
                description="People are thought to walk forward into the past and walk backward into the future, both taking place in the present, where the past and the future are constantly mediated in the ever-transforming present’ "
                icon={LocalLibrary}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Coaching"
                description={
                  '"Smallness is a state of mind." Epeli Hau\'ofa \n Utah Polynesian Professionals trace their ancestry back to the Pacific Islands. We identify and nuture emerging leaders.'
                }
                icon={RecordVoiceOver}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    )
  }
}

export default withStyles(aboutStyle)(AboutSection)
